@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";

@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@widescreen: ~"(min-width: 1430px)";
@mobileNavColor: #2c5ca8;
@castleDetailsColor: #2c5ca8;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/017/";


/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400 !important;
}


/*.OpenSansBold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700 !important;
}*/

.SairaCondensedMedium {
	font-family: 'Saira Condensed', sans-serif;
	font-weight: 500;
}

.Montserrat {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400 !important;
}

.MontserratMedium {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500 !important;
}

.MontserratSemiBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600 !important;
}

.MontserratBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700 !important;
}


html {
	font-size: 20px;
}

body {
	.BodyFont;
	background-color: #0d45ac;
	position: relative;

	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYBAMAAAA46dFkAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+nhxg7wAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAAD1BMVEUAAAAAAAAAAQEAAAEAAQMdfes5AAAAAXRSTlMAQObYZgAAAD5JREFUCNdjEBQUZGBgEGQQNFEA0owMgkICYJoRRBEJBKGUoCAyzQSkDUBmAWkBLPJwfXgBzB0QMxiRaLC7AUs2A0L9f3W+AAAAAElFTkSuQmCC");
		opacity: 0.2;
		background-size: 5px;
	}
}

.outer-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.nicefont {
}


.textContent {
	font-size: 0.8rem;
	color: #4d4d4d;
	text-align: center;

	@media @md {
		font-size: 1rem;
	}

	h1 {
		.MontserratMedium;
		font-size: 1.5rem;
		color: #000;

		@media @md {
			font-size: 1.935rem;
		}
	}
}

.lower-content h2 {
	.MontserratMedium;
	font-size: 1.5rem;
	color: #000;

	@media @md {
		font-size: 1.935rem;
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/


form {
	position: relative;
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--alt {
	}
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		//width: 1170px;
	}

	@media (min-width: 1310px) {
		padding-left: 55px;
		padding-right: 55px;
	}

	@media @widescreen {
		width: 1400px;
	}

	&--content {
		flex-grow: 1;
	}
}

.container-border {
	background-image: url("@{cloudUrl}container-border.png");
	position: absolute;
	top: 0;
	bottom: 0;
	width: 11px;
	opacity: 0.75;

	&--left {
		left: 0;
	}

	&--right {
		right: 0;
	}
}

/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/

.toplinks {
	background: #333333;

	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;

		&:nth-child(2) {
			display: none;
		}

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 5px;
		color: #fff;
		font-size: 1.3rem;
		transition: all 0.3s;
		font-size: 1.16rem;
		.Montserrat;

		&:hover, &.active, &:focus {
			color: #ffc600;
		}
	}

	&__dropdown {
		padding: 0;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background-color: inherit !important;
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.3rem;
		color: #444fff !important;

		&:hover, &:focus, &.active {
			background-color: inherit !important;
			color: #444fff !important;
		}
	}
}


.header {
	@width: 1292px;
	@height: 248px;
	position: relative;

	@media @mobile {
		text-align: center;
		padding: 80px 0 20px;
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__logo {
		.TopBarItem(535,@width,@height,auto,24,left,0);

		@media @mobile {
			display: block;
			margin: 0 auto;
		}
	}

	&__rpii {
		.TopBarItem(212,@width,@height,auto,30,right,0);

		@media @mobile {
			display: block;
			margin: 0 auto;
			max-width: 50% !important;
		}
	}

	&__contact {
		.TopBarItem(215,@width,@height,auto,129,right,254);
		.SairaCondensedMedium;
		white-space: nowrap;
		line-height: 1;

		@media @mobile {
			display: block;
			margin: 10px auto;
			font-size: 1rem;
		}

		@media @tablet {
			font-size: 2.1vw;
			direction: rtl;
		}

		@media (min-width: 1310px) {
			font-size: 1.4rem;
		}
	}

	&__phone {
		.TopBarItem(352,@width,@height,auto,79,right,254);
		.MontserratSemiBold;
		white-space: nowrap;
		line-height: 1;

		@media @mobile {
			display: block;
			margin: 5px auto;
			font-size: 1.4rem;
			padding: 5px 0;
		}

		@media @tablet {
			font-size: 3.5vw;
			text-align: right;
		}

		@media (min-width: 1310px) {
			font-size: 2.4rem;
		}
	}

	&__email {
		.TopBarItem(306,@width,@height,auto,51,right,254);
		.MontserratBold;
		white-space: nowrap;
		line-height: 1;
		color: #333333;

		&:hover {
			color: #333333;
		}

		@media @mobile {
			display: block;
			margin: 5px auto;
			padding: 5px 0;
			font-size: 1rem;
		}

		@media @tablet {
			font-size: 1.9vw;
			direction: rtl;
		}

		@media (min-width: 1310px) {
			font-size: 1.26rem;
		}
	}

	&__social-links {
		display: flex;

		@media @mobile {
			margin-top: 20px;
			justify-content: center;
		}

		@media @tablet {
			position: absolute;
			top: 9%;
			right: 19.4%;
			width: 20%;
			justify-content: flex-end;
		}
	}

	&__social-link {
		margin: 0 4px;
		width: ~'calc(25% - 8px)';

		@media @mobile {
			max-width: 50px;
		}
	}
}


.banner {
	position: relative;
	.BannerPanelSet(53.406%);

	#CarouselContainer {
		overflow: visible !important;
	}

	.carousel-indicators {
		display: none;
	}

	#CarouselPrevious, #CarouselNext {
		position: absolute;
		top: 50%;
		background-image: url("@{cloudUrl}gallery-arrow.png");
		background-size: 100% 100%;
		width: 60px;
		height: 60px;
	}

	#CarouselPrevious {
		left: 0;
		transform: translateX(-15px);

		@media @desktop {
			transform: translateX(-50%);
		}
	}

	#CarouselNext {
		right: 0;
		transform: translateX(15px)scaleX(-1);

		@media @desktop {
			transform: translateX(50%)scaleX(-1);
		}
	}
}


.service-panel {

	&__panels {
		margin-bottom: 15px;

		@media @md {
			margin-top: -50px;
			display: flex;
			justify-content: space-around;
		}
	}

	position: relative;
	color: #fff;
	text-align: center;
	padding: 20px 50px;
	position: relative;
	border: 1px solid #fff;
	box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16),inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);

	@media @md {
		padding: 20px 30px;
		width: 30%;
	}

	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-image: url("@{cloudUrl}triangles.png");
		opacity: 0.05;
	}

	h2 {
		.SairaCondensedMedium;
		margin-top: 0;
		margin-bottom: 20px;
	}

	&--1 {
		background: #0961d8;
	}

	&--2 {
		background: #04972c;
	}

	&--3 {
		background: #e41336;
	}

	&__icon {
		position: absolute;
		width: 55px;
		height: 55px;
		border: 1px solid #fff;
		top: 50%;
		left: 0;
		transform: translateX(-15px)translateY(-50%);
		box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16),inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);

		@media @md {
			top: 10%;
			transform: translateX(-50%);
		}

		&:before {
			.TransformMiddle;
			font-family: "Font Awesome 5 Free";
			font-size: 1.4rem;
		}

		&--1 {
			background: #0c3bd6;

			&:before {
				content: "\f559";
			}
		}

		&--2 {
			background: #087335;

			&:before {
				content: "\f46c";
			}
		}

		&--3 {
			background: #bc0303;

			&:before {
				content: "\f019";
			}
		}
	}

	&__content {
		font-size: 0.85rem;
	}

	&__test-search {
		position: relative;
	}

	&__search-box {
		width: 100%;
		height: 50px;
		background: transparent;
		border: 1px solid #fff;
		padding-left: 10px;

		&::placeholder {
			color: #FFF;
			opacity: 1;
		}
	}

	&__search-button {
		background: #fbba16;
		width: 33px;
		height: 33px;
		right: 0;
		left: auto;
		transform: translateX(-15px)translateY(-50%);
		box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16),inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
		color: #000;
		top: 50%;

		&:before {
			color: #000;
			content: "\f002";
		}

		&:hover {

			&:before {
				color: #000;
			}
		}
	}
}

.contact-form {
	background: #333333;
	padding: 30px 50px 50px;

	&__controls {
		position: relative;
		max-width: 900px;
		margin: 0 auto;
	}

	&__intro {
		margin-bottom: 20px;
		color: #fff;
		text-transform: uppercase;
		text-align: center;

		h2 {
			margin-top: 0;
			color: #ffc600;
			.MontserratMedium;
			font-size: 2.4rem;
		}

		p {
			color: #fff;
			font-size: 1.3rem;
		}
	}

	.form-control {
		border-radius: 0;
		min-height: 45px;
		background-color: transparent;
	}

	.FormFieldYourName {
		position: relative;
		width: 48%;

		@media @mobile {
			width: 100%;
		}
	}

	.FormFieldYourEmailAddress {
		position: relative;
		width: 48%;
		margin-top: 10px;

		@media @mobile {
			width: 100%;
		}
	}

	.FormFieldYourPhoneNumber {
		position: relative;
		width: 48%;
		margin-top: 10px;

		@media @mobile {
			width: 100%;
		}
	}

	.FormFieldYourEnquiry {
		position: absolute;
		width: 48%;
		top: 0;
		right: 0;
		bottom: 0;


		@media @mobile {
			margin-top: 10px;
			position: relative;
			width: 100%;
		}
	}

	textarea.form-control {

		@media @tablet {
			height: 155px;
		}
	}

	.btn-success {
		//.OpenSansBold;
		color: #fff;
		background-color: #089745;
		border: 2px solid #089745 !important;
		padding: 10px 25px;
		transition: all .3s;
		border: 1px solid transparent;
		text-transform: uppercase;
		border-radius: 0;
		margin-top: 25px;
		width: 20% !important;
		margin-left: 80%;
		position: relative;
		z-index: 1;
		text-align: center;
		border-radius: 0;
		.MontserratBold;

		&:hover {
			border: 2px solid #089745;
			color: #089745;
			background: #fff;
		}

		@media @mobile {
			width: 100% !important;
			margin-left: 0;
		}
	}
}

.home-gallery {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__image-panel {
		width: ~'calc(50% - 6px)';
		margin-bottom: 6px;
		position: relative;
		overflow: hidden;

		@media @md {
			width: ~'calc(25% - 6px)';
		}

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 67.92%;
		}

		a, img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;

			@supports

			(mix-blend-mode:multiply) {
				top: 0 !important;
				left: 0 !important;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

.testimonials {
	position: relative;
	margin-top: 20px;
	margin-bottom: 40px;
	background: #ffaa00;
	padding: 50px;
	box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16),inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);

	@media @mobile {
		padding: 30px 30px 50px;
	}


	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-image: url("@{cloudUrl}testimonial-faces.jpg");
		opacity: 0.1;
		mix-blend-mode: multiply;
	}

	&__title {
		position: relative;
		margin-top: 0;
		margin-bottom: 40px;
		.MontserratMedium;
		font-size: 1.8rem;

		@media @mobile {
			font-size: 1.2rem;
			margin-bottom: 30px;
		}

		@media @md {
			font-size: 2.4rem;
		}
	}


	&__button {
		position: absolute;
		top: 50%;
		background-image: url("@{cloudUrl}gallery-arrow.png");
		background-size: 100% 100%;
		width: 60px;
		height: 60px;

		&--prev {
			left: 0;
			transform: translateX(-18px);

			@media @tablet {
				transform: translateX(-50%);
			}
		}


		&--next {
			right: 0;
			transform: translateX(18px)scaleX(-1);

			@media @tablet {
				transform: translateX(50%)scaleX(-1);
			}
		}
	}

	.RotatorTestimonial {
		background: #fff;
		max-width: 800px;
		margin: 0 auto;
		box-shadow: -3px 3px 1px rgba(0,0,0,.2), inset -3px 3px rgba(0,0,0,0.2);
		margin-bottom: 3px;
		padding: 25px 50px;
		text-align: center;

		@media @mobile {
			padding: 10px;
		}

		@media @desktop {
			padding: 50px 100px;
		}
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;

		time {
			display: none;
		}

		p:first-child {
			color: #fff;
		}

		p[itemprop="reviewBody"] {
			font-size: 1.1rem;
			color: #808080;

			@media @mobile {
				font-size: 0.8rem;
			}
		}

		a[itemprop="author"] {
			color: #666666;
			font-size: 1.3rem;
			.MontserratBold;

			@media @mobile {
				font-size: 1rem;
			}
		}
	}

	#RotatorTestimonialsHeader {
		margin-bottom: 15px;

		p, a {
			text-align: center;
			color: #fff;
			font-size: 1rem;
			position: relative;
		}
	}

	.RotatorTestimonialRating {
		max-width: 280px;
		margin: 10px auto 0;
	}
}

.castlePanel {
	width: 100%;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	border: none;
	//padding: 10px;
	&:hover {
	}

	.SingleImagePanel {
		height: auto;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
		}
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			font-size: 0.9rem;
		}
	}



	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		font-size: 1rem;

		span.oldprice {
			font-size: 0.65rem;
		}

		span.newprice {
			font-size: 0.8rem;
		}
	}

	span.oldprice, span.newprice {
	}



	.castleCheckBook {
		margin-top: 10px;
		height: auto;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	font-size: 2rem;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 195px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}


.footer {
	background: #000;
	padding: 20px 0 50px;
	color: #a1a1a1;

	@media @mobile {
		text-align: center;
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__text-center-sm {
		@media @sm {
			text-align: center;
		}
	}

	&__text-right-sm {
		@media @sm {
			text-align: right;
		}
	}

	&__links {
		text-align: center;
	}

	&__link {
		margin: 0 5px;
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}

	#BCNLink {
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}
}

/*#region Animations*/

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

/*#endregion Animations*/


