/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
/*.OpenSansBold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700 !important;
}*/
.SairaCondensedMedium {
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 500;
}
.Montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}
.MontserratMedium {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500 !important;
}
.MontserratSemiBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
.MontserratBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
html {
  font-size: 20px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
  background-color: #0d45ac;
  position: relative;
}
body:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYBAMAAAA46dFkAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+nhxg7wAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAAD1BMVEUAAAAAAAAAAQEAAAEAAQMdfes5AAAAAXRSTlMAQObYZgAAAD5JREFUCNdjEBQUZGBgEGQQNFEA0owMgkICYJoRRBEJBKGUoCAyzQSkDUBmAWkBLPJwfXgBzB0QMxiRaLC7AUs2A0L9f3W+AAAAAElFTkSuQmCC");
  opacity: 0.2;
  background-size: 5px;
}
.outer-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}
.textContent {
  font-size: 0.8rem;
  color: #4d4d4d;
  text-align: center;
}
@media (min-width: 992px) {
  .textContent {
    font-size: 1rem;
  }
}
.textContent h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500 !important;
  font-size: 1.5rem;
  color: #000;
}
@media (min-width: 992px) {
  .textContent h1 {
    font-size: 1.935rem;
  }
}
.lower-content h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500 !important;
  font-size: 1.5rem;
  color: #000;
}
@media (min-width: 992px) {
  .lower-content h2 {
    font-size: 1.935rem;
  }
}
/*#endregion fonts*/
form {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1310px) {
  .container {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (min-width: 1430px) {
  .container {
    width: 1400px;
  }
}
.container--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.container-border {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/017/container-border.png");
  position: absolute;
  top: 0;
  bottom: 0;
  width: 11px;
  opacity: 0.75;
}
.container-border--left {
  left: 0;
}
.container-border--right {
  right: 0;
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #2c5ca8;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
  background: #333333;
}
.toplinks__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li:nth-child(2) {
  display: none;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 5px;
  color: #fff;
  font-size: 1.3rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.16rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #ffc600;
}
.toplinks__dropdown {
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: inherit !important;
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.3rem;
  color: #444fff !important;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: inherit !important;
  color: #444fff !important;
}
.header {
  position: relative;
}
@media (max-width: 767px) {
  .header {
    text-align: center;
    padding: 80px 0 20px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 19.2%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 41.41%;
    bottom: 9.68%;
    left: 0%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .header__rpii {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__rpii {
    position: absolute;
    width: 16.41%;
    bottom: 12.1%;
    right: 0%;
  }
}
@media (max-width: 767px) {
  .header__rpii {
    display: block;
    margin: 0 auto;
    max-width: 50% !important;
  }
}
.header__contact {
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1;
}
@media (max-width: 767px) {
  .header__contact {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__contact {
    position: absolute;
    width: 16.64%;
    bottom: 52.02%;
    right: 19.66%;
  }
}
@media (max-width: 767px) {
  .header__contact {
    display: block;
    margin: 10px auto;
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .header__contact {
    font-size: 2.1vw;
    direction: rtl;
  }
}
@media (min-width: 1310px) {
  .header__contact {
    font-size: 1.4rem;
  }
}
.header__phone {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  white-space: nowrap;
  line-height: 1;
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 27.24%;
    bottom: 31.85%;
    right: 19.66%;
  }
}
@media (max-width: 767px) {
  .header__phone {
    display: block;
    margin: 5px auto;
    font-size: 1.4rem;
    padding: 5px 0;
  }
}
@media (min-width: 768px) {
  .header__phone {
    font-size: 3.5vw;
    text-align: right;
  }
}
@media (min-width: 1310px) {
  .header__phone {
    font-size: 2.4rem;
  }
}
.header__email {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  white-space: nowrap;
  line-height: 1;
  color: #333333;
}
@media (max-width: 767px) {
  .header__email {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    width: 23.68%;
    bottom: 20.56%;
    right: 19.66%;
  }
}
.header__email:hover {
  color: #333333;
}
@media (max-width: 767px) {
  .header__email {
    display: block;
    margin: 5px auto;
    padding: 5px 0;
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .header__email {
    font-size: 1.9vw;
    direction: rtl;
  }
}
@media (min-width: 1310px) {
  .header__email {
    font-size: 1.26rem;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .header__social-links {
    margin-top: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    top: 9%;
    right: 19.4%;
    width: 20%;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.header__social-link {
  margin: 0 4px;
  width: calc(25% - 8px);
}
@media (max-width: 767px) {
  .header__social-link {
    max-width: 50px;
  }
}
.banner {
  position: relative;
}
.banner #CarouselContainer {
  padding-top: 53.406%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner #CarouselContainer {
  overflow: visible !important;
}
.banner .carousel-indicators {
  display: none;
}
.banner #CarouselPrevious,
.banner #CarouselNext {
  position: absolute;
  top: 50%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/017/gallery-arrow.png");
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
}
.banner #CarouselPrevious {
  left: 0;
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
}
@media (min-width: 1200px) {
  .banner #CarouselPrevious {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.banner #CarouselNext {
  right: 0;
  -webkit-transform: translateX(15px) scaleX(-1);
          transform: translateX(15px) scaleX(-1);
}
@media (min-width: 1200px) {
  .banner #CarouselNext {
    -webkit-transform: translateX(50%) scaleX(-1);
            transform: translateX(50%) scaleX(-1);
  }
}
.service-panel {
  color: #fff;
  text-align: center;
  padding: 20px 50px;
  position: relative;
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
          box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
}
.service-panel__panels {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .service-panel__panels {
    margin-top: -50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
@media (min-width: 992px) {
  .service-panel {
    padding: 20px 30px;
    width: 30%;
  }
}
.service-panel:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/017/triangles.png");
  opacity: 0.05;
}
.service-panel h2 {
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
}
.service-panel--1 {
  background: #0961d8;
}
.service-panel--2 {
  background: #04972c;
}
.service-panel--3 {
  background: #e41336;
}
.service-panel__icon {
  position: absolute;
  width: 55px;
  height: 55px;
  border: 1px solid #fff;
  top: 50%;
  left: 0;
  -webkit-transform: translateX(-15px) translateY(-50%);
          transform: translateX(-15px) translateY(-50%);
  -webkit-box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
          box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
}
@media (min-width: 992px) {
  .service-panel__icon {
    top: 10%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.service-panel__icon:before {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  font-family: "Font Awesome 5 Free";
  font-size: 1.4rem;
}
.service-panel__icon--1 {
  background: #0c3bd6;
}
.service-panel__icon--1:before {
  content: "\f559";
}
.service-panel__icon--2 {
  background: #087335;
}
.service-panel__icon--2:before {
  content: "\f46c";
}
.service-panel__icon--3 {
  background: #bc0303;
}
.service-panel__icon--3:before {
  content: "\f019";
}
.service-panel__content {
  font-size: 0.85rem;
}
.service-panel__test-search {
  position: relative;
}
.service-panel__search-box {
  width: 100%;
  height: 50px;
  background: transparent;
  border: 1px solid #fff;
  padding-left: 10px;
}
.service-panel__search-box::-webkit-input-placeholder {
  color: #FFF;
  opacity: 1;
}
.service-panel__search-box::-moz-placeholder {
  color: #FFF;
  opacity: 1;
}
.service-panel__search-box::-ms-input-placeholder {
  color: #FFF;
  opacity: 1;
}
.service-panel__search-box::placeholder {
  color: #FFF;
  opacity: 1;
}
.service-panel__search-button {
  background: #fbba16;
  width: 33px;
  height: 33px;
  right: 0;
  left: auto;
  -webkit-transform: translateX(-15px) translateY(-50%);
          transform: translateX(-15px) translateY(-50%);
  -webkit-box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
          box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
  color: #000;
  top: 50%;
}
.service-panel__search-button:before {
  color: #000;
  content: "\f002";
}
.service-panel__search-button:hover:before {
  color: #000;
}
.contact-form {
  background: #333333;
  padding: 30px 50px 50px;
}
.contact-form__controls {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
}
.contact-form__intro {
  margin-bottom: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
.contact-form__intro h2 {
  margin-top: 0;
  color: #ffc600;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500 !important;
  font-size: 2.4rem;
}
.contact-form__intro p {
  color: #fff;
  font-size: 1.3rem;
}
.contact-form .form-control {
  border-radius: 0;
  min-height: 45px;
  background-color: transparent;
}
.contact-form .FormFieldYourName {
  position: relative;
  width: 48%;
}
@media (max-width: 767px) {
  .contact-form .FormFieldYourName {
    width: 100%;
  }
}
.contact-form .FormFieldYourEmailAddress {
  position: relative;
  width: 48%;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .contact-form .FormFieldYourEmailAddress {
    width: 100%;
  }
}
.contact-form .FormFieldYourPhoneNumber {
  position: relative;
  width: 48%;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .contact-form .FormFieldYourPhoneNumber {
    width: 100%;
  }
}
.contact-form .FormFieldYourEnquiry {
  position: absolute;
  width: 48%;
  top: 0;
  right: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .contact-form .FormFieldYourEnquiry {
    margin-top: 10px;
    position: relative;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .contact-form textarea.form-control {
    height: 155px;
  }
}
.contact-form .btn-success {
  color: #fff;
  background-color: #089745;
  border: 2px solid #089745 !important;
  padding: 10px 25px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid transparent;
  text-transform: uppercase;
  margin-top: 25px;
  width: 20% !important;
  margin-left: 80%;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
.contact-form .btn-success:hover {
  border: 2px solid #089745;
  color: #089745;
  background: #fff;
}
@media (max-width: 767px) {
  .contact-form .btn-success {
    width: 100% !important;
    margin-left: 0;
  }
}
.home-gallery {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.home-gallery__image-panel {
  width: calc(50% - 6px);
  margin-bottom: 6px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .home-gallery__image-panel {
    width: calc(25% - 6px);
  }
}
.home-gallery__image-panel:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 67.92%;
}
.home-gallery__image-panel a,
.home-gallery__image-panel img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
@supports (mix-blend-mode:multiply) {
  .home-gallery__image-panel a,
  .home-gallery__image-panel img {
    top: 0 !important;
    left: 0 !important;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.testimonials {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  background: #ffaa00;
  padding: 50px;
  -webkit-box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
          box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.16), inset -4.243px 4.243px 0px 2px rgba(0, 0, 0, 0.11);
}
@media (max-width: 767px) {
  .testimonials {
    padding: 30px 30px 50px;
  }
}
.testimonials:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/017/testimonial-faces.jpg");
  opacity: 0.1;
  mix-blend-mode: multiply;
}
.testimonials__title {
  position: relative;
  margin-top: 0;
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500 !important;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .testimonials__title {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .testimonials__title {
    font-size: 2.4rem;
  }
}
.testimonials__button {
  position: absolute;
  top: 50%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/017/gallery-arrow.png");
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
}
.testimonials__button--prev {
  left: 0;
  -webkit-transform: translateX(-18px);
          transform: translateX(-18px);
}
@media (min-width: 768px) {
  .testimonials__button--prev {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.testimonials__button--next {
  right: 0;
  -webkit-transform: translateX(18px) scaleX(-1);
          transform: translateX(18px) scaleX(-1);
}
@media (min-width: 768px) {
  .testimonials__button--next {
    -webkit-transform: translateX(50%) scaleX(-1);
            transform: translateX(50%) scaleX(-1);
  }
}
.testimonials .RotatorTestimonial {
  background: #fff;
  max-width: 800px;
  margin: 0 auto;
  -webkit-box-shadow: -3px 3px 1px rgba(0, 0, 0, 0.2), inset -3px 3px rgba(0, 0, 0, 0.2);
          box-shadow: -3px 3px 1px rgba(0, 0, 0, 0.2), inset -3px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 3px;
  padding: 25px 50px;
  text-align: center;
}
@media (max-width: 767px) {
  .testimonials .RotatorTestimonial {
    padding: 10px;
  }
}
@media (min-width: 1200px) {
  .testimonials .RotatorTestimonial {
    padding: 50px 100px;
  }
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.testimonials .RotatorTestimonialContent time {
  display: none;
}
.testimonials .RotatorTestimonialContent p:first-child {
  color: #fff;
}
.testimonials .RotatorTestimonialContent p[itemprop="reviewBody"] {
  font-size: 1.1rem;
  color: #808080;
}
@media (max-width: 767px) {
  .testimonials .RotatorTestimonialContent p[itemprop="reviewBody"] {
    font-size: 0.8rem;
  }
}
.testimonials .RotatorTestimonialContent a[itemprop="author"] {
  color: #666666;
  font-size: 1.3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
@media (max-width: 767px) {
  .testimonials .RotatorTestimonialContent a[itemprop="author"] {
    font-size: 1rem;
  }
}
.testimonials #RotatorTestimonialsHeader {
  margin-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader p,
.testimonials #RotatorTestimonialsHeader a {
  text-align: center;
  color: #fff;
  font-size: 1rem;
  position: relative;
}
.testimonials .RotatorTestimonialRating {
  max-width: 280px;
  margin: 10px auto 0;
}
.castlePanel {
  width: 100%;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
}
.castlePanel .SingleImagePanel {
  height: auto;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-size: 0.9rem;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
  font-size: 1rem;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.65rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 0.8rem;
}
.castlePanel .castleCheckBook {
  margin-top: 10px;
  height: auto;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #2c5ca8;
  font-size: 2rem;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 195px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer {
  background: #000;
  padding: 20px 0 50px;
  color: #a1a1a1;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .footer__text-center-sm {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__text-right-sm {
    text-align: right;
  }
}
.footer__links {
  text-align: center;
}
.footer__link {
  margin: 0 5px;
  color: #a1a1a1;
}
.footer__link:hover {
  color: #a1a1a1;
}
.footer #BCNLink {
  color: #a1a1a1;
}
.footer #BCNLink:hover {
  color: #a1a1a1;
}
/*#region Animations*/
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
/*#endregion Animations*/
